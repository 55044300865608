import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import styled from "styled-components"
import { graphql } from "gatsby"
import HubspotForm from "react-hubspot-form"
import { Form } from "@components/common/form"
import Heading from "@components/elements/heading"
import Image from "@components/image"
import Div from "@components/elements/div"

const CoverWrapper = styled.a`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 4rem;
  width: 100%;
  .klipfolio-image-wrapper {
    min-height: 6rem;
    width: 100%;
  }
`

const ContactUs = ({ data }) => {
  const cover = data.image

  return (
    <Layout
      title="Contact Us"
      description="Let's get in touch and find out how we can help with your dashboard questions."
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <CoverWrapper href="https://maps.google.ca/maps?q=klipfolio&hl=en&sll=56,-96&sspn=93.572467,183.691406&hq=klipfolio&t=m&z=13&iwloc=A">
          <Image eager alt="Contact Us Google Maps Artwork" file={cover} />
        </CoverWrapper>
        <Div margin="4rem auto" textContainer>
          <Form>
            <Heading margin="0 0 1rem">Get in touch with us</Heading>
            <HubspotForm
              portalId={"7477725"}
              formId={"a0788cf8-8d01-41f2-9b12-4eee8cf54a5e"}
            />
          </Form>
        </Div>
      </Container>
    </Layout>
  )
}

ContactUs.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ContactQuery {
    image: directusFiles(
      directus_id: { eq: "5722ae62-3b3f-4a2e-b466-0c1f8f272520" }
    ) {
      cdn
      id
      placeholder
      title
    }
  }
`

export default ContactUs
